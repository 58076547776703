import React from 'react';
import '../style.css';
import './style.css';

const MapPinNewIcon = ({ fill, width, marginBottom }) => {
  return (
    <div style={{ marginBottom: `${marginBottom}px` }} className="icon-wrapper">
      {/* <svg style={{ width: `${width}px` }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.53 122.88">
        <g style={{ fill: `${fill}` }}>
          <path class="cls-1" d="M46.27,24.08A22.75,22.75,0,1,1,23.52,46.83,22.75,22.75,0,0,1,46.27,24.08Zm1.36,91.62A82.87,82.87,0,0,0,64,101.81,85.44,85.44,0,0,0,84.56,62.23c2.81-13.67,1.5-27-4.75-37.34a36.74,36.74,0,0,0-6.63-8.06C65.62,9.93,57,6.64,48.23,6.56,39.07,6.49,29.77,10,21.55,16.5a38.54,38.54,0,0,0-8.63,9.56C7.15,35.15,5.41,46.43,7.31,58.24c1.94,12,7.66,24.61,16.77,36A102.46,102.46,0,0,0,47.63,115.7Zm21.24-9.46a89.32,89.32,0,0,1-19.33,16,3.28,3.28,0,0,1-3.71.13,109.25,109.25,0,0,1-26.9-24c-9.8-12.31-16-26-18.1-39.1C-1.33,45.89.7,33,7.36,22.53a45.3,45.3,0,0,1,10.1-11.18C26.85,3.87,37.6-.09,48.29,0,58.6.09,68.79,3.92,77.6,12a43.1,43.1,0,0,1,7.82,9.52c7.15,11.8,8.71,26.83,5.57,42.05a92.2,92.2,0,0,1-22.12,42.7Z" />
        </g>
      </svg> */}

      <svg style={{ width: `${width}px` }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 86.96 122.88" >
        <g><path class="st1" d="M27.81,96.57c4.42,7.04,9.2,15.57,13.92,23.86c1.75,3.06,3.33,3.52,5.43-0.11c4.56-7.91,9.04-16.1,13.76-23.74 c14.33-23.19,37.78-45.8,19.13-77.01C63.49-8.14,18.45-5.18,4.98,20.62C-10.46,50.19,13.65,74.03,27.81,96.57L27.81,96.57z" /><path class="st0" d="M43.46,25.59c9.31,0,16.86,7.55,16.86,16.86s-7.55,16.86-16.86,16.86c-9.31,0-16.86-7.55-16.86-16.86 S34.15,25.59,43.46,25.59L43.46,25.59z" /></g></svg>
    </div>
  )
}

export default MapPinNewIcon;

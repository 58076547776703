import React, { useEffect, useState } from "react";
import "./style.css";
import GoogleMapReact from "google-map-react";
import MapPinIcon from "../Icons/MapPinIcon";
import MapPinNewIcon from "../Icons/MapPinNewIcon";
import XIcon from "../Icons/XIcon";
import MriIcon from "../Icons/MriIcon";
import HospitalIcon from "../Icons/HospitalIcon";

const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    return Math.sqrt(
      (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
    );
  }
};

const places = [
  // serbia
  {
    country: 'Srbija', title: "Beograd", lat: 44.8125, lng: 20.4612, projects: [
      { facility: 'Dr Konjović', mri: 'Philips' },
      { facility: 'Bolnica "Sveti Sava"', mri: 'GE' },
      { facility: 'Univerzitetska dečija bolnica', mri: 'Philips' },
      { facility: 'KBC Zemun', mri: 'Canon' },
      { facility: 'Klinika za digestivne bolesti KCS', mri: 'Philips' },
      { facility: 'Institut za neurologiju', mri: 'Philips' },
    ]
  },
  {
    country: 'Srbija', title: "Čačak", lat: 43.8914, lng: 20.3506, projects: [
      { facility: 'Hipokrat MR', mri: 'Philips' }
    ]
  },
  {
    country: 'Srbija', title: "Sremska Mitrovica", lat: 44.9798, lng: 19.6102, projects: [
      { facility: 'Sirmium Medic', mri: 'Philips' }
    ]
  },
  {
    country: 'Srbija', title: "Užice", lat: 43.8556, lng: 19.8425, projects: [
      { facility: 'Zdravstveni Centar Užice', mri: 'GE' }
    ]
  },
  {
    country: 'Srbija', title: "Novi Sad", lat: 45.24704, lng: 19.84669, projects: [
      { facility: 'Klinički centar Vojvodine', mri: 'GE' },
      { facility: 'Zdravlje plus', mri: 'Philips' },
    ]
  },
  {
    country: 'Srbija', title: "Sombor", lat: 45.7733, lng: 19.1151, projects: [
      { facility: 'Poliklinika Consilium', mri: 'Philips' }
    ]
  },
  // bosnia
  {
    id: 3, title: "Zenica", lat: 44.2034, lng: 17.9077, projects: [
      { facility: 'Poliklinika "DR Strika"', mri: 'GE' }
    ]
  },
  {
    id: 3, title: "Bijeljina", lat: 44.7570, lng: 19.2150, projects: [
      { facility: 'Medik - T', mri: 'Philips' }
    ]
  },
  {
    id: 3, title: "Doboj", lat: 44.7349, lng: 18.0843, projects: [
      { facility: 'Dijagnostički centar Dr Brkic', mri: 'Philips' }
    ]
  },
  {
    id: 3, title: "Brčko", lat: 44.8727, lng: 18.8106, projects: [
      { facility: 'Spec. Ordinacija ALFA', mri: 'Philips' }
    ]
  },
  {
    id: 3, title: "Zvornik", lat: 44.3865, lng: 19.1048, projects: [
      { facility: 'Opšta bolnica Zvornik', mri: 'GE' }
    ]
  },
  // macedonia
  {
    id: 3, title: "Tetovo", lat: 42.0069, lng: 20.9715, projects: [
      { facility: 'Klinička bolnica Tetovo', mri: 'GE' }
    ]
  },
  {
    title: "Skopje", lat: 41.9981, lng: 21.4254, projects: [
      { facility: 'Klinička bolnica "Sistina"', mri: 'GE' },
      { facility: 'Gradska bolnica Skopje', mri: 'GE' },
      { facility: 'Klinička bolnica "Sistina"', mri: 'GE 3T' },
    ]
  },
  {
    title: "Štip", lat: 41.7464, lng: 22.1997, projects: [
      { facility: 'Klinička bolnica Štip', mri: 'GE' },
    ]
  },
  {
    title: "Petrovec", lat: 41.9402, lng: 21.6094, projects: [
      { facility: 'Euroitalia', mri: 'GE' }
    ]
  },
  // moldavia
  {
    id: 3, title: "Balti", lat: 47.7540, lng: 27.9184, projects: [
      { facility: 'Incomed', mri: 'Philips' }
    ]
  },
  // poland
  {
    id: 3, title: "Bielsko-biala", lat: 49.8224, lng: 19.0584, projects: [
      { facility: 'Klinika Św. Łukasza', mri: 'Philips' }
    ]
  },
  // italy
  { id: 3, title: "Trapani", lat: 38.0174, lng: 12.5365, projects: [
    {facility: 'Multimedica Trapanese', mri: 'Neusoft'}
  ] },
  // india
  { title: "Karur", lng: 78.0766, lat: 10.9601, projects: [
    {facility: 'Cura Healthcare', mri: 'Philips'}
  ] },
  { title: "Jaipur", lng: 75.7873, lat: 26.9124, projects: [
    {facility: 'Cura Healthcare', mri: 'Philips'}
  ] },
  // belarus
  { id: 2, title: "Minsk", lat: 53.9006, lng: 27.5590, projects: [
    {facility: 'Avicenna Medical', mri: 'Philips'},
  ] },
  // montenegro
  { id: 3, title: "Berane", lat: 42.8379, lng: 19.8604, projects: [
    {facility: 'Poliklinika Stojanović', mri: 'GE'},
  ] },
  { id: 3, title: "Podgorica", lat: 42.4304, lng: 19.2594, projects: [
    {facility: 'Hotel Lovćen', mri: 'GE'},
  ] },
  // bulgaria
  { id: 3, title: "Sofija", lat: 42.6977, lng: 23.3219, projects: [
    {facility: 'Neo Clinic', mri: 'GE'},
    // OVO PROVERI I PROMENI
  ] },
  // germany //saarbrucken t
  { id: 3, title: "Saarbrücken", lat: 49.2402, lng: 6.9969, projects: [
    {facility: 'Joint Orthopedic Clinic', mri: 'Philips'},
    {facility: 'Joint Orthopedic Clinic (zamena magneta)', mri: 'Philips'},
  ] },
  { id: 3, title: "Schweinfurt", lat: 50.0492, lng: 10.2194, projects: [
    {facility: 'Leopoldina', mri: 'Philips'},
  ] },
  { id: 3, title: "Frankfurt", lat: 50.1109, lng: 8.6821, projects: [
    {facility: 'Main Clinic', mri: 'Philips'},
  ] },
  { id: 3, title: "Föhren", lat: 49.8588, lng: 6.7657, projects: [
    {facility: 'Promed', mri: 'Philips 3T'},
    {facility: 'InHealth mobile magnet', mri: 'Philips'},
    {facility: 'Calumet mobile magnet', mri: 'Philips'},
    {facility: 'Smith mobile magnet', mri: 'Philips'},
    {facility: 'Smith relocatable magnet', mri: 'Philips'},
    {facility: 'Promed', mri: 'Philips'},
  ] },
  { id: 3, title: "Bad Sobernheim", lat: 49.7858, lng: 7.6515, projects: [
    {facility: 'Sanomed', mri: 'Philips'},
  ] },
  { id: 3, title: "Mannheim", lat: 49.4875, lng: 8.4660, projects: [
    {facility: 'Praxis Dr J.J. Kirsch & Kollegen', mri: 'Philips'}
  ] },
];
// tip posla: konstrukcija kabine ```` servisiranje/modifikacija
const SimpleMap = () => {
  const [close, setClose] = useState(0);

  const InfoWindow = ({ title, active, projects }) => {
    if (active) {
      return (
        <div className={`pin-info-wrapper ${projects.length > 1 ? 'larger' : ''}`}>
          <div className="pin-info-header">
            <div style={{ width: '18px' }}></div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <MapPinIcon fill='#444' marginBottom={0} width={15} />
              <p>{title}</p>
            </div>
            <XIcon width={22} fill='#666' />
          </div>
          <div className="pin-info-inner-wrapper">
            {projects?.map((e, index) => (
              <>
                {projects.length > 1 && <div className="projects-counter">{index + 1}. Projekat:</div>}
                <div className="pin-info-body">
                  <div className="pin-info-row">
                    <div className="pin-info-desc">
                      <p>MRI:</p>
                    </div>
                    <div className="pin-info-label">
                      <MriIcon fill='#444' width={22} />
                      <p>{e.mri}</p>
                    </div>
                  </div>
                  <div className="pin-info-row">
                    <div className="pin-info-desc">
                      <p>Ustanova:</p>
                    </div>
                    <div className="pin-info-label">
                      <HospitalIcon fill='#444' width={22} />
                      <p>{e.facility}</p>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      );
    }
    else {
      return null;
    }
  }

  const Marker = ({ title, setCurState, lat, lng, projects }) => {
    const handleClick = () => {
      setCurState({
        lat: lat,
        lng: lng,
        title: title,
        active: true,
        projects: projects,
      })
    }

    return (
      <div onClick={handleClick} className="marker-wrapper">
        <MapPinNewIcon width={18} />
      </div>
    );
  };

  const [center, setCenter] = useState([46.506, 24.169949]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [curState, setCurState] = useState({
    lat: 37.506,
    lng: 20.169949,
    title: '',
    active: false,
    projects: null,
  });

  useEffect(() => {
    setTimeout(() => {
      if (windowWidth < 1100) {
        setCurState({
          lat: 45.506,
          lng: 20.169949,
          title: '',
          active: false,
          projects: null,
        })
      }
    }, 500)
  }, []);

  useEffect(() => {
    setCenter([curState.lat, curState.lng])
  }, [curState])

  let infoBox = <InfoWindow
    lat={curState.lat}
    lng={curState.lng}
    title={curState.title}
    active={curState.active}
    projects={curState.projects}
  />

  return (
    <div className="map">
      <GoogleMapReact
        onClick={() => setCurState({ ...curState, active: false })}
        bootstrapURLKeys={{
          key: 'AIzaSyAddxvveo89wrKJZJHfpVYn2VsZZsHhdsA',
          language: "en",
          region: "US"
        }}
        defaultCenter={[46.506, 24.169949]}
        center={center}
        defaultZoom={3.8}
        distanceToMouse={distanceToMouse}
      >
        {places.map(({ lat, lng, title, projects }) => {
          return (
            <Marker
              close={close}
              lat={lat}
              lng={lng}
              title={title}
              setCurState={setCurState}
              projects={projects}
            />
          );
        })}
        {infoBox}
      </GoogleMapReact>
    </div>
  );
}

export default SimpleMap;
